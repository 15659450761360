<template>
  <div>
    <b-card no-body class="plans-top mb-0">
      <b-card-body>
        <div class="plans-title">
          <img src="@/assets/images/icons/plano-white.png" alt="">
          <span class="text-plans">
            {{$t("Plans")}}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <b-card class="plans-middle mb-0">
      <b-card-body>
        <b-row>
          <b-col xl="2" class="plans-pro">
            <div class="month-plans-pro">
              <div></div>
              <img src="@/assets/images/icons/plano-white.png" alt="">
              <span class="month-text">
                Premium
              </span>
            </div>
            <span class="month-description">Plano Premium</span>
          </b-col>
          <b-col xl="6" class="exp-time">
            <span class="exp-title">Plano subscrito</span>
            <span class="exp-description">
              O plano subscrito é de pagamento <b>anual</b>
            </span>
            <b-progress
              max="100"
              class="exp-progress-bar"
            >
              <b-progress-bar
                class="progress-exp"
                value="75"
                label="5 meses"
              />
            </b-progress>
            <div class="plans-date">
              <span>Activo desde 01/0172022</span>
              <span>Ainda tem 4 meses</span>
            </div>
            <div class="cancel-btn">
              <b-button
                variant="none"
                class="cancel-btn-exp"
              >
                Cancelar subscrição
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, 
  BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BProgress, BProgressBar
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    BProgress,
    BProgressBar,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>